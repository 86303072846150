
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
    apiGameTopicCategoryDetail,
    apiGameTopicCategoryEdit,
    apiGameTopicCategoryAdd
} from '@/api/setting'
@Component({
    components: {}
})
export default class GameTopicCategoryEdit extends Vue {
    @Prop() aid?: any
    @Prop() cid?: number
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        formRef: any
    }
    form = {
        name: '', // 分类名称
        is_show: 1, // 是否显示 0-不显示 1-显示
        sort: 0 // 排序值，须大于或等于0
    }

    // 表单验证
    formRules = {
        name: [
            {
                required: true,
                message: '请输入分类名称',
                trigger: 'blur'
            }
        ],
        is_show: [
            {
                required: true,
                message: '请选择分类状态',
                trigger: 'change'
            }
        ]
    }
    /** E Data **/

    // 分类详情
    getCategoryDetail() {
        apiGameTopicCategoryDetail({
            aid: this.aid,
            id: this.cid
        })
            .then((res: any) => {
                this.form = res
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 新增分类
    onCategoryAdd() {
        apiGameTopicCategoryAdd({
            ...this.form,
            aid: this.aid,
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 编辑分类
    onCategoryEdit() {
        apiGameTopicCategoryEdit({
            ...this.form,
            id: this.cid,
            aid: this.aid,
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 提交
    onSubmit() {
        this.$refs.formRef.validate((valid: any) => {
            if (!valid) {
                return
            }
            if (this.cid) {
                this.onCategoryEdit()
            } else {
                this.onCategoryAdd()
            }
        })
    }

    // 弹窗打开触发
    onTrigger() {
        this.visible = true

        if (this.cid) {
            this.getCategoryDetail()
        }
    }

    // 关闭弹窗
    close() {
        this.visible = false

        this.form = {
            name: '', // 分类名称
            is_show: 1, // 是否显示 0-不显示 1-显示
            sort: 0 // 排序值，须大于或等于0
        }
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
